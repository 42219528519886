<template>
	<div class="table-grid-mysource table-responsive">
		
		<table class="oml-datatable table-md">
			<thead>
				<tr>
					<th v-for="(item, key) in columns" @click="sortBy(item.column)" :class="[ sortKey == item.column ? 'active' : '', item.column == 'source' ? '' : 'text-center' ]" v-bind:key="key">
						<!-- {{ key | capitalize }} -->
						{{ item.title }}
						<span v-if="item.column == 'processingRate'">
							<i class="fa fa-question-circle text-primary" id="popover-processingRate"></i>
							<b-popover target="popover-processingRate" triggers="hover" placement="auto">
								{{ $t('iProcessingRate') }}
							</b-popover>
						</span>
						
						<span v-if="item.column == 'qualificationRate'">
							<i class="fa fa-question-circle text-primary" id="popover-qualificationRate"></i>
							<b-popover target="popover-qualificationRate" triggers="hover" placement="auto">
								{{ $t('iQualificationRate') }}
							</b-popover>
						</span>
						
						<!--div class="arrow" :class="sortOrders[item.column] > 0 ? 'asc' : 'dsc'"></div-->
						<div style=" display: inline-block;float: right;opacity: 0.66; " v-if="item.column != 'details'">
							<i class="fa fa-sort-amount-asc" v-if="sortOrders[item.column] > 0"></i>
							<i class="fa fa-sort-amount-desc" v-else></i>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(entry, id) in filteredHeroes" v-bind:key="id">
					<!-- entry.id -->
					<td v-for="(item, key) in columns" v-bind:key="key">
						<div v-if="item.column == 'source'" class="d-flex align-items-center" style="width: 30em;">
							<img :src="`/img/${arrayType[entry.type]}`" style="width: 35px; height: 35px; margin-right: 14px; border-radius: 3px;" @click="$router.push({path: '/myleads/' + entry.id, params: {source: entry.id}})" class="c-pointer" />
							<div>
								<div style="font-weight: 400; padding-top: 1px;" :title="entry[item.column]">
									<span style="color: #797979;" @click="$router.push({path: '/myLeads/' + entry.id, params: {source: entry.id}})" class="c-pointer">
									
									{{ entry[item.column].replace(' - ' + entry['created_at'] ,'').replace('-' + entry['created_at'] ,'') | MaxLength }}
									
									</span>
									<a v-if="entry.type == 'facebook_leadgen'" target="_blank" :href="`https://www.facebook.com/${entry.sourceData.page.id}`" style=" padding-left: 5px; padding-top: 1px; ">{{ $t('VisitPage') }}</a>
								</div>
								<span style="font-size: 12px;color: #9599a3;margin-top: 7px;display: inline-block;" @click="$router.push({path: '/myLeads/' + entry.id, params: {source: entry.id}})" class="c-pointer">
									{{ $t('Publishedon') }} : 
									<span>
										{{ entry['created_at'] | moment('timezone', workspaceTimezone, workspaceDateFormat) }}
									</span>
								</span>
							</div>
						</div>
						<div v-else-if="item.column == 'leads'" class="text-center">
							<router-link :to="{path: '/myLeads/' + entry.id, params: {source: entry.id}}" style="color: #797979;">
								{{ entry[item.column] }}
							</router-link>
						</div>
						<div v-else-if="item.column == 'actions'">
							
							<center>
								<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret>
									<template v-slot:button-content>
										<i v-if="!$store.state.active || ($store.state.active && 'edit sources' in $store.state.permissions) || ($store.state.active && 'delete sources' in $store.state.permissions)" class="fa fa-ellipsis-v dropdown-icon"></i>
									</template>
									<!--b-dropdown-item href="#">Action</b-dropdown-item>
									<b-dropdown-item href="#">Another action</b-dropdown-item-->
									<div class="editsource" v-if="!$store.state.active || ($store.state.active && 'edit sources' in $store.state.permissions)">
										
										<b-dropdown-item v-if="entry['type'] == 'facebook_leadgen' || entry['type'] == 'zapier_app'" @click="$emit('eventCallBack', 'email', entry)"><i class="fa fa-envelope-o"></i> {{ $t('WelcomeEmail') }}</b-dropdown-item>
										<b-dropdown-item v-if="entry['type'] == 'facebook_leadgen' || entry['type'] == 'zapier_app'" @click="$emit('eventCallBack', 'alert', entry)"><i class="fa fa-bell-o"></i> {{ $t('LeadsAlert') }}</b-dropdown-item>
										<b-dropdown-item v-if="entry['type'] == 'facebook_leadgen'" @click="$emit('eventCallBack', 'edit', entry)"><i class="fa fa-eye"></i> {{ $t('Mapping') }}</b-dropdown-item>
										<b-dropdown-item v-if="entry['type'] == 'webhook' || entry['type'] == 'clickFunnels' || entry['type'] == 'landingi'" @click="$emit('eventCallBack', 'webhook_edit', entry)"><i class="fa fa-edit"></i> {{ $t('Edit') }}</b-dropdown-item>
									</div>
									<div v-if="!$store.state.active || ($store.state.active && 'delete sources' in $store.state.permissions)">
										<b-dropdown-item  @click="deleteSource(entry,id)"><i class="fa fa-trash"></i> {{ $t('DeleteSource') }}</b-dropdown-item>
									</div>
									
								</b-dropdown>
							</center><!--  v-html="entry[item.column]" -->
						</div>
						<div v-else-if="item.column == 'processingRate' || item.column == 'qualificationRate'" class="text-center">
							<span v-if="entry[item.column] < 10" class="text-danger">{{ entry[item.column] }} %</span>
							<span v-else-if="entry[item.column] < 80" class="text-warning">{{ entry[item.column] }} %</span>
							<span v-else class="text-success">{{ entry[item.column] }} %</span>
						</div>
						<center v-else v-html="entry[item.column]"></center>
					</td>
				</tr>
				
				<tr v-if="filteredHeroes.length == 0">
					<td :colspan="columns.length" style="font-weight: unset;"><center>{{ $t('NoSource') }}</center></td>
				</tr>
				
			</tbody>
		</table>
		
		<b-modal id="modal-delete-source" centered hide-footer title="Confirmation"> <!-- size="sm" -->
			<div>
				<div class="row">
					<div class="col-12 mb-1">
						<p class="m-0" style=" color: #000; font-size: 14px; " v-html="$t('DeletingConfirg', { name: selectedSource.source })"></p>
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-right">
						<b-button size="sm" variant="success" @click="deleteEvent()">
							<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{ $t('YesDeleteIt') }}
						</b-button>
					</div>
				</div>
			</div>
		</b-modal>
		
	</div>
</template>

<script>
	// register the grid component
	
	export default {
		props: {
			mySources	: Array,
			columns		: Array,
			searchQuery : String
		},
		data() {
			var sortOrders = {}
			this.columns.forEach(function (key) {
				sortOrders[key.column] = 1
			})
			return {
				arrayType:{
					"csv"				:'Integration/sources/excel.png',
					"xlsx"				:'Integration/sources/excel.png',
					"zapier_app"		:'oml-icon-zapier.png',
					"facebook_leadgen"	:'Integration/sources/oml-icon-facebook_leadgen.png',
					"gravityforms"		:'Integration/sources/gravity-forms.png',
					"gravity"			:'Integration/sources/gravity-forms.png',
					"manually_added"	:'Integration/sources/manually_added.png',
					"google_sheet"		:'Integration/sources/google-sheets.png',
					"web_site"			:'Integration/sources/web_site.png',
					"demo_data"			:'Integration/sources/demo-data.jpg',
					"webhook"			:'Integration/sources/webhook.png',
					"googleLead"		:'Integration/sources/google-lead-form.png',
					"clickFunnels"		:'Integration/sources/clickFunnels.png',
					"landingi"			:'Integration/sources/landingi.jpg',
				},
				sortKey: '',
				sortOrders: sortOrders,
				
				loadingAction : false,
				selectedSource: {
					id		: '',
					element	: '',
					source	: '',
				},
				
			}
		},
		
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
			
			deleteEvent() {
				
				let that = this
				that.loadingAction = true
				// console.log( that.selectedSource )
				
				let url		= 'sources/delete_source/' + that.selectedSource.element.id
				let method	= 'DELETE'
				let data	= {provider : "delete sources",permissionid : 3}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					let confirm = response.data
					if(confirm.success) {
						that.mySources.splice(that.selectedSource.id,1);
						
						that.leftSidebarInfo()
						that.notifyMe(this.$t('ChangesSaved'), 'success')
						that.loadingAction = false
						that.$bvModal.hide('modal-delete-source');
						that.$store.dispatch('GetClients')
						
					}
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingAction = false
				})
				
			},
			
			sortBy(key) {
				this.sortKey = key
				this.sortOrders[key] = this.sortOrders[key] * -1
			},
			
			deleteSource(element,id) {
				let that = this
				that.$bvModal.show('modal-delete-source');
				that.selectedSource.id = id
				that.selectedSource.element = element
				that.selectedSource.source = element.source
			},
			leftSidebarInfo() {
				let that = this
				let url   = 'left_sidebar'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log(response)
				}).catch(( {response} ) => {
					console.log(response)
				})
			},
		},
		
		computed: {
			filteredHeroes() {
				var sortKey = this.sortKey
				var filterKey = this.searchQuery && this.searchQuery.toLowerCase()
				var order = this.sortOrders[sortKey] || 1
				var mySources = this.mySources
				if (filterKey) {
					mySources = mySources.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				}
				if (sortKey) {
					mySources = mySources.slice().sort(function (a, b) {
						a = a[sortKey]
						b = b[sortKey]
						return (a === b ? 0 : a > b ? 1 : -1) * order
					})
				}
				return mySources
			}
		},
		filters: {
			capitalize: function (str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
			MaxLength: function (str) {
				if( str != null && str != '' ) {
					return str.trim().length > 45 ? str.trim().substring(0, 45) + "...." : str.trim()
				} else {
					return ''
				}
			}
		},
	}
	
</script>

<style lang="scss">
	
	.table-grid-mysource {
		
		.dropdown-item {
			&:hover {
				background-color: #eee;
			}
		}
		
		.search-md {
			padding: 12px 0px;
			float: right;
		}
		
		.dropdown-item:hover, .dropdown-item:focus {
			color: #404040;
		}
		
		.table-md {
			border-top: 1px solid #f1f1f1;
			width: 100%;
			
			thead {
				border-bottom: 1px solid #eee;
			}
			
			tbody {
				tr {
					border-bottom: 1px solid #eee;
				}
			}
			
			th {
				color: #000;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				
				padding: 18px 6px;
				line-height: 2.428571;
				
			}
			
			/* td {
				background-color: #f9f9f9;
			} */
			
			td {
				padding: 19px 8px;
				font-size: 13px;
				font-weight: 700;
			}
			
			/* th.active {
				color: #fff;
			} */
			
			th.active .arrow {
				opacity: 1;
			}
			
			.arrow {
				display: inline-block;
				vertical-align: middle;
				margin-left: 6px;
				opacity: 0.66;
			}
			
			.arrow.asc {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-bottom: 4px solid #9599a3;
			}
			
			.arrow.dsc {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid #9599a3;
			}
			
		}
	}
	
</style>
